import React from 'react'
import { compose } from 'recompose'
import SEO from '../../../components/seo'
import { AuthUserContext, withAuthorization } from '../../../components/Session'
import { Galleries } from '../../../Views'
import queryString from 'query-string'
import { navigate } from 'gatsby'
import * as ROUTES from '../../../constants/routes'

const IndexPage = (props) => {

    const queryValues = queryString.parse(props.location.search)
  
	const content = (auth) => {
       
        const isBrowser = typeof window !== "undefined"
       if(isBrowser) {
        const user = window.localStorage.getItem('authUser')
		if(user){
			return <Galleries path="/galleries" authUser={auth} />
		} else {
			if(queryValues.id) {
              navigate(`/dashboard/gallery/${queryValues.id}`)
			} else {
				navigate(ROUTES.LOG_IN)
			}
		}
       } else {
           return
       }
		
	}

    return (
        <>
            <SEO title="Galleries" />
            <AuthUserContext.Consumer>
                {authUser => (
                    content(authUser)
                )}
            </AuthUserContext.Consumer>
        </>
    )
}

// const condition = authUser => !!authUser

export default IndexPage

// export default compose(
//     withAuthorization(condition),
// )(IndexPage)